<template>
  <div>
    <Head />

    <!-- 常見問題 -->
    <div class="wrapper fqa" v-if="FAQData">
      <section class="breadcrumb-box">
        <div class="container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">首頁</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                常見問題
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section class="main">
        <div class="container">
          <img class="h1-img" src="/img/h1-fqa.svg" alt="">
          <h1>常見問題</h1>
          <div class="searth">
            <div class="form-group">
              <select class="form-control" id="option1" v-model="OrganId">
                <option value="">全部單位</option>
                <option
                  v-for="(Organ, index) in FAQData.Organs"
                  :key="index"
                  :value="Organ.OrganId"
                >
                  {{ Organ.OrganName }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <!-- <label for=""></label> -->
              <input
                type=""
                class="form-control"
                id=""
                placeholder="有什麼我能幫助您的呢？"
                v-model="Keyword"
              />
            </div>
            <div class="btn">
              <button><img src="/img/search-icon.svg" alt="" /></button>
            </div>
          </div>
          <ul class="fqa-box" v-if="FAQs">
            <li v-for="FAQ in FAQs" :key="FAQ.FAQId">
              <div class="title">
                <div class="eye" v-if="false">
                  <img src="/img/eye-icon.svg" alt="" />
                  <p>000</p>
                </div>
                <div class="question">
                  <div>Q</div>
                  {{ FAQ.Question }}
                </div>
                <div>{{ FAQ.Organ.OrganName }}</div>
                <div class="open"><div class="line"></div></div>
              </div>
              <div class="text-box">
                <div class="answer">
                  <p>處理情形（答覆內容）</p>
                  <p v-if="FAQ.Organ.OrganName">回覆單位：{{ FAQ.Organ.OrganName }}</p>
                </div>
                <div class="text" v-html="FAQ.AnswerContent"></div>
                <div class="time">
                  <p v-if="FAQ.QuestionCategory">問題類別：{{ FAQ.QuestionCategory }}</p>
                  <p>
                    張貼日期：{{
                      FAQ.PublicTime | timeString("YYYY-MM-DD")
                    }}
                  </p>
                  <p v-if="FAQ.ChangeTime">
                    修改日期：{{ FAQ.ChangeTime | timeString("YYYY-MM-DD") }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
          <!-- 頁碼 -->
          <section class="page" v-if="pageAll > 1">
            <paginate
                :no-li-surround="false"
                :page-count="pageAll"
                :page-range="3"
                :margin-pages="1"
                :click-handler="loadList"
                :prev-text="'<'"
                :next-text="'>'"
                :prev-link-class="''"
                :next-link-class="''"
                :container-class="'pagination'"
                :page-link-class="''"
                :active-class="'active'"
                :active-link-class="'active'"
                :hide-prev-next="false"
              >
              </paginate>
          </section>
        </div>
      </section>
    </div>
    <!-- wrapper -->

    <Footer />
  </div>
</template>
<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Paginate from "vuejs-paginate";

Vue.component("paginate", Paginate);

export default {
  name: "FAQ",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      FAQData: null,
      FAQs: null,
      OrganId: "",
      pageAll: 0,
      perPage: 10,
      Keyword: "",
    };
  },
  watch: {
    OrganId(newVal, oldVal) {
      this.loadList(1);
    },
    Keyword(newVal, oldVal) {
      this.loadList(1);
    },
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/FAQ",
        {
          params: {},
        }
      )
      .then((response) => {
        this.FAQData = response.data;
        //this.OrganId = this.FAQData.OrganId;
        this.loadList(1);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {},
  updated: function () {
    // 常見問題 開闔
    $(".fqa-box .title").unbind();
    $(".fqa-box .title").click(function () {
      $(this).parent().siblings().children(".text-box").slideUp("add");
      $(this).siblings().slideToggle("add");

      $(this).parent().siblings().find(".line").removeClass("add");
      $(this).find(".line").toggleClass("add");
    });
  },
  methods: {
    loadList: function (page) {
      var that = this;
      $.ajax({
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/FAQList",
        type: "post",
        data: {
          OrganId: that.OrganId,
          keyword: that.Keyword,
          page: page,
          perPage: that.perPage,
        },
        dataType: "json",
        error: function () {},
        success: function (res) {
          if (res.status == 1) {
            that.FAQs = res.FAQs;
            that.perPage = res.perPage;
            that.pageAll = Math.max(Math.ceil(res.total / that.perPage), 1);
            document.body.scrollTop = 0;
          }
        },
      });
    },
  },
};
</script>

